import React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Videos from './components/Videos/videos';
import Chronology from './components/Chronology/chronology';
import Gallery from './components/Gallery/gallery';
import Home from './components/Home/home';
import Header from './components/Header/header';
import Footer from './components/Footer/footer';

function App() {
  return (
    <>
    <BrowserRouter>
    <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="videos" element={<Videos />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="chronology" element={<Chronology />} />
        </Routes>
    <Footer />
    </BrowserRouter>
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
