import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { tsaiImages } from '../../data/images';
import styles from './gallery.module.scss';
import GalleryImage from './GalleryImage/galleryImage';

const Gallery = (): JSX.Element => {
  const [filter, setFilter] = useState<string | number>('');
  const decades = [
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    2000,
  ];

  const countries = ['China', 'Japan', 'Philippines', 'Taiwan'];

  return (
    <>
      <h1>Gallery</h1>
      <div className={styles.filters}>
        <button className={styles.allBtn} onClick={() => setFilter('')}>All</button>
        <Accordion>
          <Accordion.Item eventKey="0">
          <Accordion.Header>
          <span className={styles.filterLabel}>Country Filter</span>
        </Accordion.Header>
        <Accordion.Body>
        <div className={styles.countryFilter}>
          {countries.map((country) => {
            return (<button className={styles.countryBtn} onClick={() => setFilter(country)}>{country}</button>)
          })}
        </div>
        </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
          <Accordion.Header>
          <span className={styles.filterLabel}>Decades Filter</span>
        </Accordion.Header>
        <Accordion.Body>
        <div className={styles.decadeFilter}>
          {decades.map((decade) => {
            return <button className={styles.decadeBtn} onClick={() => setFilter(decade)}>{decade}'s</button>;
          })}
        </div>
        </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <small className={styles.instruction}>Click on image for full size and to view description</small>
      <div className={styles.container}>
        {filter
          ? tsaiImages
              .sort((a, b) => a.decade - b.decade)
              .filter((img) => img.metadata === filter || img.decade === filter)
              .map((img) => {
                return (
                  <GalleryImage
                    smallSrc={img.smallSrc}
                    src={img.src}
                    description={img.description}
                    credit={img.credit}
                  />
                );
              })
          : tsaiImages.sort((a, b) => a.decade - b.decade).map((img) => {
              return (
                <GalleryImage
                  smallSrc={img.smallSrc}
                  src={img.src}
                  description={img.description}
                  credit={img.credit}
                />
              );
            })}
      </div>
    </>
  );
};

export default Gallery;
