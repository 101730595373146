import React from 'react';
import Header from '../Header/header';
import profile from '../../images/fujian-tragicparting.jpg';
import styles from './home.module.scss';

const Home = (): JSX.Element => {
  return (
    <>
      <div className={styles.logo}>
        <div className={styles.engNameGroup}>
          <span className={styles.lastName}>TSAI</span>
          <span className={styles.firstName}>CHI-KUN</span>
        </div>
        <span className={styles.cnName}>蔡繼琨</span>
      </div>
      <span className={styles.date}>1912 - 2004</span>
      <div className={styles.profile}>
        <img src={profile} className={styles.imgSize} alt="Tsai Chi-Kun" />
        <span className={styles.caption}>
          Source:{' '}
          {
            <a
              href="https://tmi.openmuseum.tw/search?_text_=%E8%94%A1%E7%B9%BC%E7%90%A8"
              target="_blank"
              rel="noreferrer"
            >
              Taiwan Music Institute
            </a>
          }
        </span>
      </div>
      <p>
        Meet the legendary man who is known as the "Founder of Fujian Music Conservatory",
        "Father of Taiwan Symphony", has earned the praise of Philippine
        presidents (Magsaysay, Garcia, Macapagal, Marcos), whose musical career
        spans 8 decades across China, Taiwan, and the Philippines. Explore the
        historic life of Prof. Tsai Chi-Kun.
      </p>
    </>
  );
};

export default Home;
