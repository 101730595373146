import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import styles from './footer.module.scss';

const Footer = (): JSX.Element => {
  return (
    <div className={styles.footer}>
      <Navbar bg="dark" variant="dark">
          <Container>
          <Navbar.Brand className={styles.title}>Tsai Chi-Kun</Navbar.Brand>
          <small>Made with <span style={{ color: 'red' }}>&#10084;</span> by a grandson</small>
          <a className={styles.link} href="mailto:developer@tsaichikun.com">Contact</a>
          </Container>
      </Navbar>
    </div>
  );
};

export default Footer;
