import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import './header.module.scss';
import { Link } from 'react-router-dom';
import styles from './header.module.scss';

const Header = (): JSX.Element => {
  return (
    <div className={styles.header}>
      <Navbar collapseOnSelect fixed="top" bg="dark" expand="sm" variant="dark">
        <Container>
          <Navbar.Brand className={styles.title}>Tsai Chi-Kun</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link eventKey="1" as={Link} to="/" className={styles.link}>
                  Home
              </Nav.Link>
              <Nav.Link eventKey="2" as={Link} to="/videos" className={styles.link}>
                  Videos
              </Nav.Link>
              <Nav.Link eventKey="3" as={Link} to="/gallery" className={styles.link}>
                  Gallery
              </Nav.Link>
              <Nav.Link eventKey="4" as={Link} to="/chronology" className={styles.link}>
                  Chronology
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
