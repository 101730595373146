import React, { useState } from 'react';
import { Figure, Modal } from 'react-bootstrap';
import styles from './galleryImage.module.scss';

interface IImageProps {
  smallSrc: any;
  src: any;
  description: string;
  credit: string;
}

const GalleryImage = ({
  smallSrc,
  src,
  description,
  credit,
}: IImageProps): JSX.Element => {
  const [show, setShow] = useState(false);

  const sourceLink = (title: string, url: string) => {
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {title}
      </a>
    );
  }

  let source = null;

  if (credit === 'tmi') {
    source = sourceLink('TMI', 'https://tmi.openmuseum.tw/search?_text_=%E8%94%A1%E7%B9%BC%E7%90%A8')
  };
  if (credit === 'ntnu') {
    source = sourceLink('NTNU', 'http://history.lib.ntnu.edu.tw/omeka/items/show/24317')
  };
  if (credit === 'book') {
    source = "Tsai Chi-Kun Book"
  };
  if (credit === 'Grandson') {
    source = "Grandson"
  }

  return (
    <>
      <Modal show={show} fullscreen="xxl-down" onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Tsai Chi-Kun</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>
          <Figure>
            <Figure.Caption className={styles.caption}>
              <p className={styles.description}> {description} </p>
            </Figure.Caption>
            <Figure.Image src={src} />
          </Figure>
        </Modal.Body>
      </Modal>
      <Figure onClick={() => setShow(true)}>
        <Figure.Image width={171} height={180} alt={description} src={smallSrc} />
        <Figure.Caption className={styles.caption}>
              <small className={styles.source}>
                Source: {source}
              </small>
        </Figure.Caption>
      </Figure>
    </>
  );
};

export default GalleryImage;
