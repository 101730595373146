export const tsaiImages = [
  {
    src: require('../images/18yo.jpg'),
    smallSrc: require('../images/small-width-171/18yo.jpg'),
    description: '18 years old in Jimei Advanced Normal School',
    metadata: 'China',
    decade: 20,
    credit: 'tmi',
  },
  {
    src: require('../images/36-japan-group.jpg'),
    smallSrc: require('../images/small-width-171/36-japan-group.jpg'),
    description:
      "On December 3, 1936, during his stay in Japan, he took a group photo with the members of the Chinese Students' Association---Chinese Academic Arts Society: Tsai Chi-Kun and Xiao Erhua (who was studying at the Tokyo Conservatory at the time). The fifth from the left in the third row is Tsai Chi-Kun, and the first from the right in the third row is Xiao Erhua.",
    metadata: 'Japan',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/37-winning.jpg'),
    smallSrc: require('../images/small-width-171/37-winning.jpg'),
    description:
      "On January 1, 1937, after winning the prize for his work 'Xunjiang Fishing Fire', Tsai Chi-Kun was summoned by Xu Shiying, the ambassador of the Republic of China to Japan, and a celebration banquet was held in the Embassy of the Republic of China in Tokyo in Tokyo. Tsai Chi-Kun is the fifth from right in the front row and Xu Shiying is in the front row.",
    metadata: 'Japan',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/40-marcus.jpg'),
    smallSrc: require('../images/small-width-171/40-marcus.jpg'),
    description:
      'In 1940, Tsai Chi-Kun (left) and Ma Gushi, a foreign professor of Fujian Music College (right). Tsai Chi-Kun is on the left and Magusi is on the right.',
    metadata: 'China',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/45-firstperformance.jpg'),
    smallSrc: require('../images/small-width-171/45-firstperformance.jpg'),
    description:
      'On December 15, 1945, the Symphony Orchestra of the General Command of the Guard of Taiwan Province performed its first public performance at Zhongshan Hall, Taipei City. Tsai Chi-Kun directed the chorus to perform.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/45-firstperformance2.jpg'),
    smallSrc: require('../images/small-width-171/45-firstperformance2.jpg'),
    description:
      'On December 15, 1945, the Symphony Orchestra of the Taiwan Provincial Guard Command held its first public concert at Zhongshan Hall, Taipei City. Tsai Chi-Kun conducted a military band performance.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/46-grain.jpg'),
    smallSrc: require('../images/small-width-171/46-grain.jpg'),
    description:
      ' In November 1946, General Tsai, who was working for the welfare of the people, took a photo at the Taiwan Provincial Grain Adjustment Committee and all the members of the committee. The chairman was General Ke Yuanfen. The one on the left is Tsai Chi-Kun.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/46-keyuanfen.jpg'),
    smallSrc: require('../images/small-width-171/46-keyuanfen.jpg'),
    description:
      'In 1946, Tsai Chi-Kun took a group photo with his colleague, General Ke Yuanfen, while serving in the Taiwan Provincial Garrison Command. Tsai Chi-Kun is squatting in the front and Ke Yuanfen is the second left in the back.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/46-taipei.jpg'),
    smallSrc: require('../images/small-width-171/46-taipei.jpg'),
    description:
      'In 1946, Tsai Chi-Kun directed the group members to practice at the new site of the Symphony Orchestra of the Office of the Chief Executive of Taiwan Province-the former West Hongan Temple in Zhonghua Road, Taipei City.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/47-concert.jpg'),
    smallSrc: require('../images/small-width-171/47-concert.jpg'),
    description:
      'February 11-13, 1947, the ninth regular performance of the Symphony Orchestra of the Office of the Chief Executive of Taiwan Province.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/50s-mso.jpg'),
    smallSrc: require('../images/small-width-171/50s-mso.jpg'),
    description: 'Teaching the Manila Symphony Orchestra',
    metadata: 'Philippines',
    decade: 50,
    credit: 'tmi',
  },
  {
    src: require('../images/67-changhui.jpg'),
    smallSrc: require('../images/small-width-171/67-changhui.jpg'),
    description:
      'In December 1967, Tsai Chi-Kun took a group photo with Xu Changhui when they met. They are both important musicians who are willing to promote talents.',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/67-taiwan.jpg'),
    smallSrc: require('../images/small-width-171/67-taiwan.jpg'),
    description:
      'In November 1967, nearly two decades after leaving Taiwan, Tsai Chi-Kun conducted the Provincial Symphony Orchestra for the first time in Taiwan.',
    metadata: 'Taiwan',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/69-shaoru.jpg'),
    smallSrc: require('../images/small-width-171/69-shaoru.jpg'),
    description:
      'On May 14-15, 1969, Tsai Chi-Kun conducted the Philippine Symphony Orchestra to accompany my nationality soprano Sun Shaoru.',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/90yo.jpg'),
    smallSrc: require('../images/small-width-171/90yo.jpg'),
    description:
      'Tsai Chi-Kun, who has reached 90 years of age, is still alive on the podium, directing the Fujian Symphony Orchestra.',
    metadata: 'China',
    decade: 90,
    credit: 'tmi',
  },
  {
    src: require('../images/98-delun.jpg'),
    smallSrc: require('../images/small-width-171/98-delun.jpg'),
    description:
      'On November 21, 1998, the famous Chinese conductor Li Delun (1917~2001) took a photo with Tsai Chi-Kun when he visited the Fujian Conservatory of Music. Li Delun on the right.',
    metadata: 'China',
    decade: 90,
    credit: 'tmi',
  },
  {
    src: require('../images/39-manilaspeech.jpg'),
    smallSrc: require('../images/small-width-171/39-manilaspeech.jpg'),
    description:
      "In March 1939, the 'Southern Overseas Chinese Consolation Group' was held in Manila, Philippines, where the concept of the war of resistance was promoted. Tsai Chi-Kun, the head of the delegation, delivered a speech.",
    metadata: 'Philippines',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/45-groupphoto.jpg'),
    smallSrc: require('../images/small-width-171/45-groupphoto.jpg'),
    description:
      "In the winter of 1945, Tsai Chi-Kun took a photo with colleagues and other friends of the Taiwan Provincial Police Command Headquarters in front of the original site of the Provincial Symphony Orchestra, the Taipei No. 3 High School Principal's Memorial Hall. The second from the right is Tsai Chi-Kun.",
    metadata: 'China',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/99-concert-poster.jpg'),
    smallSrc: require('../images/small-width-171/99-concert-poster.jpg'),
    description:
      'From December 8th to 16th, 1999, Tsai Chi-Kun conducted the first celebration of Bailing. The performances were in Quanzhou, Jimei, Xiamen and Fuzhou.',
    metadata: 'China',
    decade: 90,
    credit: 'tmi',
  },
  {
    src: require('../images/battlefieldtroupe.jpg'),
    smallSrc: require('../images/small-width-171/battlefieldtroupe.jpg'),
    description:
      "In June 1938, Tsai Chi-Kun served as the head of the 'Battlefield Singing Troupe of the Education Department of Fujian Provincial Government'",
    metadata: 'China',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/changhui.jpg'),
    smallSrc: require('../images/small-width-171/changhui.jpg'),
    description:
      'In December 1967, Tsai Chi-Kun introduced Taiwanese musician Xu Changhui to the Philippine music circles, and indirectly contributed to the establishment of the Asian Composers Alliance. The second from the right is Tsai Chi-Kun, and the second from the left is Xu Changhui.',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/chineseacademyofsciences.jpg'),
    smallSrc: require('../images/small-width-171/chineseacademyofsciences.jpg'),
    description:
      ' In November 1967, Dr. Zhang Qiyun, Dean of the Chinese Academy of Sciences, hired Tsai Chi-Kun as a philosopher of the Academy to commend his achievements in music.',
    metadata: 'China',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/conducting.jpg'),
    smallSrc: require('../images/small-width-171/conducting.jpg'),
    description: 'Conducting',
    metadata: '',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/couplepic.jpg'),
    smallSrc: require('../images/small-width-171/couplepic.jpg'),
    description: 'Tsai Chi-Kun & Yeh Bao Yi',
    metadata: 'Philippines',
    decade: 50,
    credit: 'tmi',
  },
  {
    src: require('../images/familyphoto.jpg'),
    smallSrc: require('../images/small-width-171/familyphoto.jpg'),
    description: 'Tsai family',
    metadata: 'Philippines',
    decade: 50,
    credit: 'tmi',
  },
  {
    src: require('../images/foreignprofs.jpg'),
    smallSrc: require('../images/small-width-171/foreignprofs.jpg'),
    description:
      'In January 1941, Tsai Chi-Kun took a group photo with foreign professors of Fujian Music College: Nicorov, Manzheke, and Magus. The middle seater is Tsai Chi-Kun, and from the left to the left are Nicorov, Manzheke, and Magus.',
    metadata: 'China',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/fujian-alumni.jpg'),
    smallSrc: require('../images/small-width-171/fujian-alumni.jpg'),
    description:
      'In June 1987, Tsai Chi-Kun took a group photo with alumni of the National Fujian Music College after the school history seminar. The middle seater is Tsai Chi-Kun.',
    metadata: 'China',
    decade: 80,
    credit: 'tmi',
  },
  {
    src: require('../images/fujian-former-school.jpg'),
    smallSrc: require('../images/small-width-171/fujian-former-school.jpg'),
    description:
      "In June 1987, Tsai Chi-Kun was at the former site of Fujian Music Academy next to Yanxi at the foot of Shangji Mountain in Yong'an.",
    metadata: 'China',
    decade: 80,
    credit: 'tmi',
  },
  {
    src: require('../images/fujian-graduation.jpg'),
    smallSrc: require('../images/small-width-171/fujian-graduation.jpg'),
    description:
      "The second phase of the Music Teacher Training Class of the Provincial Fujian Music College took a photo at the graduation commemorative of the Chongren Hall of Fujian Music College, Shangjishan, Yong'an. The middle seater is Principal Tsai Chi-Kun.",
    metadata: 'China',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/fujian-tragicparting.jpg'),
    smallSrc: require('../images/small-width-171/fujian-tragicparting.jpg'),
    description:
      "On January 30, 1941, teachers and students of the Provincial Fujian Music College sang Tsai Chi-Kun's oratorio 'The Tragic Parting'. One of the soloists was Ye Baoyi, and the conductor was Tsai Chi-Kun himself. The one on the left is Ye Baoyi.",
    metadata: 'China',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/fujian-training.jpg'),
    smallSrc: require('../images/small-width-171/fujian-training.jpg'),
    description:
      ' In December 1940, the trainees of the Music Teacher Training Class of Fujian Music College, the principal and the principal Tsai Chi-Kun, and the head teacher Ye Baoyi. The middle seated person is Tsai Chi-Kun, and the second right seated person is Ye Baoyi.',
    metadata: 'China',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/fujita.jpg'),
    smallSrc: require('../images/small-width-171/fujita.jpg'),
    description:
      'After performing in the Philippines in February 1965, the Japanese pianist Azusa Fujita (Mrs. Deng Changguo) took a group photo with the Chinese Ambassador to the Philippines, Mr. Hang Liwu and Mrs. Tsai Chi-Kun. The first from the right and the second from the right are Hang Liwu and his wife, and the second from the left is Tsai Chi-Kun.',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/fujita2.jpg'),
    smallSrc: require('../images/small-width-171/fujita2.jpg'),
    description:
      'Held a joint concert with conductor Tsai Chi-Kun, pianist Azusa Fujita, and violinist Masaru in Manila. The first and second from left are Tsai Chi-Kun and Fujita Azusa.',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/general.jpg'),
    smallSrc: require('../images/small-width-171/general.jpg'),
    description:
      'General Tsai Chi-Kun is in front of the headquarters of the Taiwan Provincial Garrison Command.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/yebaoyi-conductor.jpg'),
    smallSrc: require('../images/small-width-171/yebaoyi-conductor.jpg'),
    description: 'Ye Baoyi conducts the Philippine Overseas Chinese Chorus.',
    metadata: 'Philippines',
    decade: 50,
    credit: 'tmi',
  },
  {
    src: require('../images/yebaoyi-garden.jpg'),
    smallSrc: require('../images/small-width-171/yebaoyi-garden.jpg'),
    description: 'Ye Baoyi in their home in San Juan, Philippines',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/yebaoyi-solo.jpg'),
    smallSrc: require('../images/small-width-171/yebaoyi-solo.jpg'),
    description:
      "'Professor Ye Baoyi Soprano Solo Concert', on March 14, 1949 at Zhongshan Hall, Taipei City, Zhangzhou Beautiful Girl --- Ye Baoyi held the only recital in Taiwan on March 14, 1949 at Zhongshan Hall, Taipei City. Accompaniment by Sui Xiliang.",
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/yebaoyi-tomb.jpg'),
    smallSrc: require('../images/small-width-171/yebaoyi-tomb.jpg'),
    description:
      'Tsai Chi-kun visiting the tomb of Ye Baoyi in Manila Chinese Cemetery',
    metadata: 'Philippines',
    decade: 90,
    credit: 'tmi',
  },
  {
    src: require('../images/japan.jpg'),
    smallSrc: require('../images/small-width-171/japan.jpg'),
    description: 'Photo while in Japan',
    metadata: 'Japan',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/kiwanis-trophy.jpg'),
    smallSrc: require('../images/small-width-171/kiwanis-trophy.jpg'),
    description: 'Kiwanis Trophy',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/kiwanis.jpg'),
    smallSrc: require('../images/small-width-171/kiwanis.jpg'),
    description:
      'On October 19, 1966, Tsai Chi-Kun was awarded a certificate and medal by the Manila Kiwanis Club and the International Brotherhood, in recognition of his contributions to the music industry and cultural exchanges. The one on the right is Tsai Chi-Kun.',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/macicong.jpg'),
    smallSrc: require('../images/small-width-171/macicong.jpg'),
    description:
      'Tsai Chi-Kun once hired the well-known musician Ma Sicong as the conductor of the Symphony Orchestra of the Office of the Chief Executive of Taiwan Province in May 1946. The picture shows a group photo of the two reuniting overseas many years later.',
    metadata: 'Taiwan',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/marcos-sunshaoru-ntnu.jpg'),
    smallSrc: require('../images/small-width-171/marcos-sunshaoru-ntnu.jpg'),
    description:
      'President Marcos message to Filipinos in support of the concerts by Soprano Sun Shao Ru with the Manila Symphony Orchestra conducted by Tsai Chi-Kun',
    metadata: 'Philippines',
    decade: 60,
    credit: 'ntnu',
  },
  {
    src: require('../images/military.jpg'),
    smallSrc: require('../images/small-width-171/military.jpg'),
    description:
      'Tsai Chi-Kun, wearing a straight military uniform, and colleagues from the Taiwan Provincial Garrison Command. On the right is Tsai Chi-Kun.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/militaryband.jpg'),
    smallSrc: require('../images/small-width-171/militaryband.jpg'),
    description:
      'The military band of the Taiwan Provincial Garrison Command takes a group photo in front of the department (now the Supervision Institute of Zhongxiao West Road, Taipei City).',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/mso-yebaoyi.jpg'),
    smallSrc: require('../images/small-width-171/mso-yebaoyi.jpg'),
    description: 'Ye Baoyi performing for the Manila Symphony Orchestra',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/mso.jpg'),
    smallSrc: require('../images/small-width-171/mso.jpg'),
    description: 'Tsai Chi-Kun conducting the Manila Symphony Orchestra',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/nanyang.jpg'),
    smallSrc: require('../images/small-width-171/nanyang.jpg'),
    description:
      'Tsai Chi-Kun, the head of the young and energetic "Fujian Provincial Government Nanyang Overseas Chinese Condolence Group". On the right is Tsai Chi-Kun.',
    metadata: 'China',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/nanyanggroup.jpg'),
    smallSrc: require('../images/small-width-171/nanyanggroup.jpg'),
    description:
      'In June 1939, Tsai Chi-Kun and all members of the "Fujian Provincial Government Nanyang Overseas Chinese Condolence Group". In the front row is Tsai Chi-Kun.',
    metadata: 'China',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/poster-1stconcerttaiwan.jpg'),
    smallSrc: require('../images/small-width-171/poster-1stconcerttaiwan.jpg'),
    description:
      'The announcement of the first public performance of the Symphony Orchestra of the Taiwan Provincial Guard Command.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/principal.jpg'),
    smallSrc: require('../images/small-width-171/principal.jpg'),
    description:
      'A good man in Quanzhou---Tsai Chi-Kun, the youngest college principal in the country in the 1940s.',
    metadata: 'China',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/retirement.jpg'),
    smallSrc: require('../images/small-width-171/retirement.jpg'),
    description:
      'On December 8, 1999, Bailing opened one conductor at his retirement concert.',
    metadata: 'China',
    decade: 90,
    credit: 'tmi',
  },
  {
    src: require('../images/sea-conf-group.jpg'),
    smallSrc: require('../images/small-width-171/sea-conf-group.jpg'),
    description:
      'In August 1955, Tsai Chi-Kun represented the Philippines at the first music conference in Southeast Asia. The first from the left in the front row is my country’s representative Dai Cuilun, the second from the left is Tsai Chi-Kun, and the second from the right is Liang Zaiping.',
    metadata: 'Philippines',
    decade: 50,
    credit: 'tmi',
  },
  {
    src: require('../images/sea-conference.jpg'),
    smallSrc: require('../images/small-width-171/sea-conference.jpg'),
    description:
      'In 1955, Tsai Chi-Kun served as the vice chairman of the first music conference in Southeast Asia. The second from the right is Tsai Chi-Kun.',
    metadata: 'Philippines',
    decade: 50,
    credit: 'tmi',
  },
  {
    src: require('../images/shen.jpg'),
    smallSrc: require('../images/small-width-171/shen.jpg'),
    description:
      'Professor Shen Xueyong, the former chairman of the Wenjian Association, visited the Philippines in March 1961 and participated in the "China-Philippines Joint Music Concert". The Filipino violinists Redentor Romero and Tsai Chi-Kun also performed at the same time. From left: Luo Meiluo, Shen Xueyong, and Tsai Chi-Kun.',
    metadata: 'Philippines',
    decade: 60,
    credit: 'tmi',
  },
  {
    src: require('../images/taiwan-group.jpg'),
    smallSrc: require('../images/small-width-171/taiwan-group.jpg'),
    description:
      'Group photo with Taiwanese musicians. From left: Wang Jinghui, Weng Lvping, Robert Scholz (Robert Scholz), Tsai Chi-Kun, Chen Xinzhen.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/taiwanconcert.jpg'),
    smallSrc: require('../images/small-width-171/taiwanconcert.jpg'),
    description: 'A music concert hosted by the Taiwan Provincial Exposition.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/taiwanmarch.jpg'),
    smallSrc: require('../images/small-width-171/taiwanmarch.jpg'),
    description:
      'The score of "Taiwan March" composed by Ke Yuanfen and Tsai Chi-Kun.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'tmi',
  },
  {
    src: require('../images/young.jpg'),
    smallSrc: require('../images/small-width-171/young.jpg'),
    description: 'A young Tsai Chi-Kun',
    metadata: 'China',
    decade: 20,
    credit: 'tmi',
  },
  {
    src: require('../images/chiangkaishek.jpg'),
    smallSrc: require('../images/small-width-171/chiangkaishek.jpg'),
    description: 'On October 21, 1946, Chiang Kai-shek and his wife flew to Taipei to participate in the anniversary of the Taiwan Rehabilitation. Chen Yi (right 1) and Tsai Chi-Kun (left 2) were greeted at the airport.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/taiwan-welcome.jpg'),
    smallSrc: require('../images/small-width-171/taiwan-welcome.jpg'),
    description: 'On November 18, 1967, Professor Tsai Chi-Kun was warmly welcomed when he arrived in Taiwan.',
    metadata: 'Taiwan',
    decade: 60,
    credit: 'book',
  },
  {
    src: require('../images/taiwan50thanniv.jpg'),
    smallSrc: require('../images/small-width-171/taiwan50thanniv.jpg'),
    description: 'In December 1995, Tsai Chi-Kun participated in the celebration of the 50th anniversary of the establishment of the Taiwan Provincial Symphony Orchestra with Shen Xueyong (right) and Chen Chengxiong (left).',
    metadata: 'China',
    decade: 90,
    credit: 'book',
  },
  {
    src: require('../images/76-taiwan-orchestra.jpg'),
    smallSrc: require('../images/small-width-171/76-taiwan-orchestra.jpg'),
    description: 'In 1976, Professor Tsai Chi-Kun performed in the Taiwan Orchestra.',
    metadata: 'Taiwan',
    decade: 70,
    credit: 'book',
  },
  {
    src: require('../images/sea-4thconf.jpg'),
    smallSrc: require('../images/small-width-171/sea-4thconf.jpg'),
    description: "In November 1976, Tsai Chi-Kun spoke at the Fourth Conference of the Asian Composers' Union.",
    metadata: 'Unknown',
    decade: 70,
    credit: 'book',
  },
  {
    src: require('../images/taiwan-rehearsal.jpg'),
    smallSrc: require('../images/small-width-171/taiwan-rehearsal.jpg'),
    description: 'In the 1970s, Professor Tsai Chi-Kun rehearsed the orchestra in Taiwan.',
    metadata: 'Taiwan',
    decade: 70,
    credit: 'book',
  },
  {
    src: require('../images/taiwan-symphony-speech.jpg'),
    smallSrc: require('../images/small-width-171/taiwan-symphony-speech.jpg'),
    description: 'Professor Tsai Chi-Kun recalled the creation of the Taiwan Provincial Symphony Orchestra',
    metadata: 'Taiwan',
    decade: 90,
    credit: 'book',
  },
  {
    src: require('../images/taiwansymphony57thanniv.jpg'),
    smallSrc: require('../images/small-width-171/taiwansymphony57thanniv.jpg'),
    description: '2002 December 1, Professor Tsai Jikun invited to attend the Taiwan Symphony Orchestra 57th Anniversary celebration regiment, cut the ribbon for the newly built concert hall.',
    metadata: 'Taiwan',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/taiwan-keelung.jpg'),
    smallSrc: require('../images/small-width-171/taiwan-keelung.jpg'),
    description: 'Tsai Chi-Kun and Ke Yuanfen (left 2) and Fan Wei (left 3) landed in Keelung',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/general-close.jpg'),
    smallSrc: require('../images/small-width-171/general-close.jpg'),
    description: 'Major General Tsai Chi-Kun, who participated in the ceremony of receiving the fall of Taiwan Province.',
    metadata: 'Taiwan',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/translator.jpg'),
    smallSrc: require('../images/small-width-171/translator.jpg'),
    description: "Tsai Chi-Kun serves as translator for Chen Yi's speech",
    metadata: 'Taiwan',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/yanguiting.jpg'),
    smallSrc: require('../images/small-width-171/yanguiting.jpg'),
    description: 'Tsai Chi-Kun and colleagues from the Provincial Department of Education in front of the “Yan Guiting”',
    metadata: 'China',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/fujian-2ndgraduation.jpg'),
    smallSrc: require('../images/small-width-171/fujian-2ndgraduation.jpg'),
    description: 'In January 1941, the provincial president Chen Yi and other provincial officials attended the second graduation ceremony of the Fujian Musical Music Teacher Training Class.',
    metadata: 'China',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/fujian-inauguration.jpg'),
    smallSrc: require('../images/small-width-171/fujian-inauguration.jpg'),
    description: 'On February 22, 1940, Chen Yi (front row left 14) attended the inauguration ceremony of the Fujian (Province) Music Academy.',
    metadata: 'China',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/fujian-teachers.jpg'),
    smallSrc: require('../images/small-width-171/fujian-teachers.jpg'),
    description: 'At the beginning of the establishment of Fujian Musical College, President Tsai Chi-Kun (front row left 5) and teacher Li Shuhua (front row left 6) and other teachers',
    metadata: 'China',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/shanghai.jpg'),
    smallSrc: require('../images/small-width-171/shanghai.jpg'),
    description: 'In July 1940, Tsai Chi-Kun was in Shanghai with some foreign musicians.',
    metadata: 'China',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/foreignprofs2.jpg'),
    smallSrc: require('../images/small-width-171/foreignprofs2.jpg'),
    description: 'President Tsai Chi-Kun and the professor of Fujiannesian violin Nikorov (Bulgarian, left), cello Professor Manker (German Jew, post-middle), theoretical composition professor Marcus (Austrian, right).',
    metadata: 'China',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/ruins.jpg'),
    smallSrc: require('../images/small-width-171/ruins.jpg'),
    description: 'President Tsai Chi-Kun was in front of the ruins after being bombed by Japanese planes',
    metadata: 'China',
    decade: 30,
    credit: 'book',
  },
  {
    src: require('../images/fujian-1sttour.jpg'),
    smallSrc: require('../images/small-width-171/fujian-1sttour.jpg'),
    description: 'In January 1941, a group photo of the first tour of the Fujian Provincial Music Academy',
    metadata: 'China',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/lastgraduation.jpg'),
    smallSrc: require('../images/small-width-171/lastgraduation.jpg'),
    description: "On June 20, 1942, the first graduation of the Fujian Musical Normal Teacher's Division was taken. This is the last graduation ceremony of the president of Tsai Chi-Kun (front row, right 3).",
    metadata: 'China',
    decade: 40,
    credit: 'book',
  },
  {
    src: require('../images/youngcouple.jpg'),
    smallSrc: require('../images/small-width-171/youngcouple.jpg'),
    description: 'In the course of running a school in Fujian, Tsai Chi-Kun also met his wife, Ms. Ye Bao, who had been with him for thirty-five years.',
    metadata: 'China',
    decade: 30,
    credit: 'book',
  },
  {
    src: require('../images/68-intlmusicouncil.jpg'),
    smallSrc: require('../images/small-width-171/68-intlmusicouncil.jpg'),
    description: '1968 - As a representative of the Philippines, he attended the International Music Council meeting in New York and Washington.',
    metadata: 'China',
    decade: 60,
    credit: 'book',
  },
  {
    src: require('../images/fishinglightonsenriver.jpg'),
    smallSrc: require('../images/small-width-171/fishinglightonsenriver.jpg'),
    description: "'Xunjiang Fishing Fire', Cai Jikun's orchestral sketch creation, is a title work. It describes Cai Jikun’s childhood when he was studying at Jimei Primary School in Xiamen Jimei School, watching villagers fishing in the Xunjiang Fishing Village next to the elementary school. This work is based on traditions. 'Nan Yin' is the composition material, using the Chinese national style pentatonic scale, with Western functional harmony. This is the manuscript of the score.",
    metadata: 'China',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/fishinglightonsenriver-performance.jpg'),
    smallSrc: require('../images/small-width-171/fishinglightonsenriver-performance.jpg'),
    description: 'On November 4, 1936, Cai Jikun performed the award-winning performance of "Xunjiang Fishing Fire", which was performed by the Japanese New Symphony Orchestra conducted by Professor Masao Oki. The front stands on the right is Cai Jikun, and the left is Ogi Masao.',
    metadata: 'Japan',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/memorialbuilding.jpg'),
    smallSrc: require('../images/small-width-171/memorialbuilding.jpg'),
    description: 'Tsai Chi-Kun Memorial Building',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial-lobby.jpg'),
    smallSrc: require('../images/small-width-171/memorial-lobby.jpg'),
    description: 'Tsai Chi-Kun Memorial Lobby',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial1.jpg'),
    smallSrc: require('../images/small-width-171/memorial1.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial2.jpg'),
    smallSrc: require('../images/small-width-171/memorial2.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial3.jpg'),
    smallSrc: require('../images/small-width-171/memorial3.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial4.jpg'),
    smallSrc: require('../images/small-width-171/memorial4.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial5.jpg'),
    smallSrc: require('../images/small-width-171/memorial5.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial6.jpg'),
    smallSrc: require('../images/small-width-171/memorial6.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial7.jpg'),
    smallSrc: require('../images/small-width-171/memorial7.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial8.jpg'),
    smallSrc: require('../images/small-width-171/memorial8.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial9.jpg'),
    smallSrc: require('../images/small-width-171/memorial9.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial10.jpg'),
    smallSrc: require('../images/small-width-171/memorial10.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial11.jpg'),
    smallSrc: require('../images/small-width-171/memorial11.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial12.jpg'),
    smallSrc: require('../images/small-width-171/memorial12.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial13.jpg'),
    smallSrc: require('../images/small-width-171/memorial13.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/memorial14.jpg'),
    smallSrc: require('../images/small-width-171/memorial14.jpg'),
    description: 'Tsai Chi-Kun Memorial Hall',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/20thannivofschool.jpg'),
    smallSrc: require('../images/small-width-171/20thannivofschool.jpg'),
    description: 'In 2014, a commemorative plate was created for the 20th anniversary of the founding of Fujian Music Conservatory',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/94-school.jpg'),
    smallSrc: require('../images/small-width-171/94-school.jpg'),
    description: 'Fujian Music Conservatory Campus',
    metadata: 'China',
    decade: 90,
    credit: 'book',
  },
  {
    src: require('../images/bronze-statue.jpg'),
    smallSrc: require('../images/small-width-171/bronze-statue.jpg'),
    description: 'A bronze statue was erected in memory of Tsai Chi-Kun, located in the Fujian Music Conservatory grounds',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/kzib.jpg'),
    smallSrc: require('../images/small-width-171/kzib.jpg'),
    description: 'Performing for the KZIB radio station in Manila',
    metadata: 'Philippines',
    decade: 30,
    credit: 'book',
  },
  {
    src: require('../images/fujianband.jpg'),
    smallSrc: require('../images/small-width-171/fujianband.jpg'),
    description: 'School band photo in 1931',
    metadata: 'China',
    decade: 30,
    credit: 'book',
  },
  {
    src: require('../images/conducting2.jpg'),
    smallSrc: require('../images/small-width-171/conducting2.jpg'),
    description: 'In performance in the 90s',
    metadata: 'China',
    decade: 90,
    credit: 'book',
  },
  {
    src: require('../images/30s-jimei.jpg'),
    smallSrc: require('../images/small-width-171/30s-jimei.jpg'),
    description: 'As a student in Jimei',
    metadata: 'China',
    decade: 30,
    credit: 'book',
  },
  {
    src: require('../images/taiwansymphonyformermembers.jpg'),
    smallSrc: require('../images/small-width-171/taiwansymphonyformermembers.jpg'),
    description: 'Group photo of former Taiwan symphony members',
    metadata: 'Taiwan',
    decade: 90,
    credit: 'book',
  },
  {
    src: require('../images/39-groupphoto.jpg'),
    smallSrc: require('../images/small-width-171/39-groupphoto.jpg'),
    description: '1939, in Manila as the head of the Southern Fujian Overseas Chinese Friendship Group',
    metadata: 'Philippines',
    decade: 30,
    credit: 'book',
  },
  {
    src: require('../images/xijinping.jpg'),
    smallSrc: require('../images/small-width-171/xijinping.jpg'),
    description: 'Tsai Chi-Kun meets with then Fujian Governor, Xi JinPing',
    metadata: 'China',
    decade: 2000,
    credit: 'book',
  },
  {
    src: require('../images/99-eldestsister.jpg'),
    smallSrc: require('../images/small-width-171/99-eldestsister.jpg'),
    description: 'Tsai Chi-Kun and eldest sister Tsai Yi-Xiong',
    metadata: 'China',
    decade: 90,
    credit: 'tmi',
  },
  {
    src: require('../images/39-chenyi-chinesecondolence.jpg'),
    smallSrc: require('../images/small-width-171/39-chenyi-chinesecondolence.jpg'),
    description: "In March 1939, Chairman Cai Jikun and all the members of the 'Fujian Provincial Government Nanyang Overseas Chinese Consolation Mission' visited the provincial chairman Chen Yi and Mrs. Chen's wife, Ms. Gu Yuehaozi, before departure. The sixth from the right is Cai Jikun, and the middle right is Chen Yi.",
    metadata: 'China',
    decade: 30,
    credit: 'tmi',
  },
  {
    src: require('../images/55-seaconference.jpg'),
    smallSrc: require('../images/small-width-171/55-seaconference.jpg'),
    description: 'Held in Manila, Philippines from August 29th to 31st, 1955, Dai Chilun participated in the conference on behalf of the country. This is a group photo of the conference delegates, from left are Tsai Chi-Kun, Dai Cilun, Liang Zaiping',
    metadata: 'Philippines',
    decade: 50,
    credit: 'tmi',
  },
  {
    src: require('../images/75-taiwan.jpg'),
    smallSrc: require('../images/small-width-171/75-taiwan.jpg'),
    description: 'On December 19, 1975, it was held at the Chung Hsing Hall in Taichung. The guest conductor was Dai Cilun and the violin soloist was Situ Xingcheng. This is the 30th anniversary group photo. From left are Deng Hanjin, Dai Cilun, Tsai Chi-Kun, Chen Tunchu and Zhang Jiren.',
    metadata: 'Taiwan',
    decade: 70,
    credit: 'tmi',
  },
  {
    src: require('../images/53-mso.jpg'),
    smallSrc: require('../images/small-width-171/53-mso.jpg'),
    description: 'On March 27, 1953, Cai Jikun and the Manila Symphony Orchestra of the Philippines sang and conducted for R. B. Jimenez, a professor at Eastern University.',
    metadata: 'Philippines',
    decade: 50,
    credit: 'tmi',
  },
  {
    src: require('../images/100-cover.jpg'),
    smallSrc: require('../images/small-width-171/100-cover.jpg'),
    description: 'Front cover of the 100th birthday commemoration of Prof. Tsai Chi-Kun',
    metadata: 'China',
    decade: 2000,
    credit: 'Grandson'
  },
  {
    src: require('../images/100-back.jpg'),
    smallSrc: require('../images/small-width-171/100-back.jpg'),
    description: 'Back cover of the 100th birthday commemoration of Prof. Tsai Chi-Kun',
    metadata: 'China',
    decade: 2000,
    credit: 'Grandson',
  },
  {
    src: require('../images/100-stamps.jpg'),
    smallSrc: require('../images/small-width-171/100-stamps.jpg'),
    description: 'Commemorative stamps in honor of Prof. Tsai Chi-Kun',
    metadata: 'China',
    decade: 2000,
    credit: 'Grandson',
  },
  {
    src: require('../images/100-writeup.jpg'),
    smallSrc: require('../images/small-width-171/100-writeup.jpg'),
    description: 'Summary writeup of the life of Prof. Tsai Chi-Kun',
    metadata: 'China',
    decade: 2000,
    credit: 'Grandson',
  },
];

export default { tsaiImages };

