export const early = [
  {
    date: '1912',
    text: 'On Aug 15, Tsai Chi-Kun was born in Jinshi, Jingting Street, West Street, Quanzhou City, Fujian Province, and ranked fourth. His ancestral home is Qingyang, Jinjiang.',
  },
  {
    date: '1914',
    text: 'My father, Mr. Tsai Shihai, died of illness, and Ms. Guo Gangjing took up the responsibility of raising children.',
  },
  {
    date: '1918',
    text: 'I went to Quanzhou Peishi Elementary School to study. Later, I moved to Xiamen and moved to Jimei Elementary School. Later I began to learn to play the organ.',
  },
  {
    date: '1924',
    text: 'After graduating from Jimei Elementary School, he later went to school in Jimei Shuangshi Middle School, Shanghai Xinmin Middle School and Xiamen Jimei Middle School.',
  },
  {
    date: '1930',
    text: 'He was admitted to the music major of Jimei Senior Teachers College.',
  },
  {
    date: '1932',
    text: "After graduating from Xiamen Jimei Senior Teacher's College, he was appointed as a coach of the band because of his excellent music performance.",
  },
  {
    date: '1933',
    text: 'I went to Japan to study at the Imperial Music College in Tokyo, where I studied theoretical composition and command with Professor Masu Masahiro and Professor Suzuki.',
  },
  {
    date: '1936',
    text: "In school, orchestral 'Xunjiang fishing boat' ( Fishing Light ON Sen River ) for that year 9 months Japan organized by the International Society of Composers symphony raised First Prize ( Won First Prize of International's Award ). November 4 days, Xunjiang fishing boat New Japan Philharmonic Orchestra conductor (now mentor Professor Masao large wooden NHK Symphony Orchestra predecessor) Court Japanese modern works of the Night was held in the Youth Hall premiere outside Tokyo Meiji Shrine.",
  },
  {
    date: '1937',
    text: "On New Year's Day, Xu Shiying, Ambassador of the Republic of China to Japan, held a congratulatory meeting to praise his achievements in composition. Anti-Japanese military, Tsai Chi-Kun resolutely returned to China, and closely integrated the music industry with the overall situation of the anti-war map. He successively founded the Fujian Provincial Music Education Research Association, the Fujian Music Teacher Training Class, the Fujian Provincial Government Education Department Battlefield Songs Group, and Fujian Province. Nanyang overseas Chinese consolation group, etc., rushed to the front line of anti-enemy, carried out literature and art propaganda, strive for overseas Chinese support, and join the national anti-Japanese cause. Created a Fujian Music Academy to cultivate music talents and achieve outstanding results.",
  },
  {
    date: '1937',
    text: "In spring, he returned to China and returned to China as a music instructor of the Education Department of the Fujian Provincial Government. In April, in the North Gate Pocket School of Fuzhou City, the first 'Fujian Primary and Secondary School Music Teacher Training Class' was established as the training class teacher. In November, he participated in the establishment of the 'Fujian Provincial Music Education Research Association' in Fuzhou.",
  },
  {
    date: '1938',
    text: "In June, the music teacher training class was mainly organized, and the 'Fujian Province Battlefield Songs Group' was organized and served as the head of the group. The delegation led a group to tour the anti-Japanese front lines such as Daixi and Weinan to perform condolence performances.",
  },
  {
    date: '1939',
    text: "In March, he was appointed to organize the 'Southern Fujian Overseas Chinese Friendship Group' of the Fujian Provincial Government and served as the head of the delegation. He went to the Philippines and other places to publicize the war against Japan, and to help the overseas Chinese in the province. In July, after returning to China, he went to Yongan, the provincial capital of the war. With the support of Chen Yi, the chairman of the Fujian Provincial Government, he planned to set up the 'Fujian Provincial Music Academy'. November 6 days, any of the Preparatory Committee for the University of Fujian Provincial Committee. November 20 days, for the first time held its first concert in Fuzhou.",
  },
];

export const year1940s = [
  {
    date: '1940',
    text: 'February 22 days, the provincial government during the war in Jishan outskirts of Chongren Tang Wing, formally established "Fujian Provincial Music Institute", led by president. At the beginning of July, I married Ms. Ye Bao.',
  },
  {
    date: '1941',
    text: 'In January, he led the "Fujian Provincial Music Academy Touring Performance Group" for the first time in the province.',
  },
  {
    date: '1942',
    text: 'In November, the aim was to transform the Fujian Music College from a provincial to a national and to the Ministry of Education. After the war, he accompanied Chongqing.',
  },
  {
    date: '1944',
    text: "On April 21, he was appointed as a member of the Music Education Committee of the Ministry of Education. November 19 days, the Army hired as military music school music consultant. In November, he was appointed as an adjunct professor at the Music Department of the Teachers College of the National Chengchi University. In August 1945, the War of Resistance Against Japan was won. At the invitation of Taiwanese Chief Executive Chen Yi, Tsai Chi-Kun went to Taiwan to participate in the reception of the Japanese surrender after the victory of the Anti-Japanese War and was responsible for expanding Taiwan's modern music industry. At that time, Taiwan did not have a professional symphony orchestra, nor did it have a professional music college. Under the arrangement of friends, Tsai Chi-Kun visited the famous music masters in Taiwan, and established a symphony orchestra of the Taiwan Provincial Police Command, and served as the head and conductor. The Taiwan Symphony Orchestra aims to promote music and serve the people. Under the leadership of Tsai Chi-Kun, it is only three years. The scale of the orchestra has become the crown of the Far East. Tsai Chi-Kun is also known as the 'father of Taiwan Symphony'.",
  },
  {
    date: '1945',
    text: "After the victory of the Anti-Japanese War, he was hired to go to Taiwan in September to take charge of the reception of all military equipment in the Japanese army. On October 25th, he participated in the signing ceremony of the Japanese surrender in Taiwan in the auditorium of the Taipei City Hall. November 8 days, the garrison headquarters Taiwan delegation for Symphony Orchestra, Symphony Orchestra began Chouzu matters. December 1, the 'Taiwan Garrison General Headquarters Symphony' was established, headed and conductor. On the 15th to 16th, the Conducting Orchestra held a concert in Taipei City Hall.",
  },
  {
    date: '1946',
    text: "February 10 days, the command 'Taiwan Garrison General Headquarters Symphony' premiered in Taipei, receiving praise. March 1 day after either renamed 'Taiwan Administrative office Symphony' The head, replaced under the Taiwan Administrative office. On May 8, the first regular concert was held at the Zhongshan Hall, renamed by the Taipei City Hall. In July, Ma Sicong led the 'Shanghai Music Association Visiting Group' to visit Taiwan and invited Ma Sicong to be the orchestra conductor of the orchestra.On October 25th, to celebrate the anniversary of Taiwan's restoration, the Orchestra held a celebration concert at the Zhongshan Hall in Taipei to entertain people from all walks of life.",
  },
  {
    date: '1947',
    text: "February 11 to 13 days, the orchestra Zhongshan Hall in Taipei held three concerts. On the 28th, the '228 incident' broke out in Taiwan. In April, the 'Taiwan Provincial Chief Executive's Symphony Orchestra' founded the 'Learning' bimonthly publication, and served as the publisher, and was the editor of Qi Tianrui. This is the earliest music magazine in Taiwan. On May 1st, Taiwan’s provincial government reformed the system and became the head of the 'Taiwan Provincial Government Symphony Orchestra' after the renaming. On the 8th to 9th, the Orchestra held its tenth regular concert. On June 3, he was appointed as a music professor at National Taiwan University. December 3, the Taiwan Provincial council, reiterated its recommendation that the provincial government, the provincial government cut shrink band transformed into a military band, cultural heard the news, argue, letter requests the Government to retain the original establishment. December 25 days, organized literary and art circles in Taiwan music, art, drama, literature, etc., set up a group of artists in Taiwan Province 'Taiwan Association of Art building,' the first incumbent president.",
  },
  {
    date: '1948',
    text: "March 1, the 'Taiwan Provincial Government Symphony Orchestra' belonged to 'Taiwan Provincial Art Association building,' preparing austerity employees 95 people, remains 'the Taiwan Provincial Government Symphony' name. On September 21, he was hired as the concurrent professor of the Music Department by Xie Dongjun, Dean of the Taiwan Provincial Teachers College. On October 25~28, in order to cooperate with the Taiwan Provincial Expo, the Orchestra held a four- day concert in the practice hall of the Orchestra . The Beethoven Ninth Symphony (chorus) was performed for the first time in Taiwan Province.",
  },
  {
    date: '1949',
    text: 'April 6 to 7 days before departure last orchestra concert held on the twenty-ninth regular basis. On May 15th, he was transferred to the Commercial Counselor of the Embassy in the Philippines. The orchestra was replaced by Wang Xiqi as the head and conductor.',
  },
];

export const year1950s = [
  {
    date: '1950',
    text: 'November 21 days, should be appointed player Manila Symphony Orchestra ( Manila Concert Orchestra ) music director and conductor. Later, he was hired as a professor at the Central escolars Universite School of Music in the Philippines.',
  },
  {
    date: '1952',
    text: 'In the autumn, Ye Hao was employed as a professor of vocal music at the Conservatory of Music at the Oriental University of the Philippines. December 18 days of Christmas concert directed by the Vice President of the Philippines Luobi Zi praise.',
  },
  {
    date: '1953',
    text: "March 27 days, a professor at the University of the East enjoy Mrs. Min Lisi ( R • B • JIMENEZ ) and Italian tenor Shami He ( Guiseppe • SAVIO ) jointly concert as a conductor, the show was a great success, by the President of the Philippines Season Reno's praise.",
  },
  {
    date: '1954',
    text: 'December 9 days, Christmas concerts command, praised Philippine President Magsaysay.',
  },
  {
    date: '1955',
    text: "In August, the first music conference in Southeast Asia was held in Manila, and he was elected as the initiator of the conference. He was elected as the vice chairman and chairman of the Music Education and Music Culture Committee. His work “浔江渔火” performed at the conference.In August, the first music conference in Southeast Asia was held in Manila, and he was elected as the initiator of the conference. He was elected as the vice chairman and chairman of the Music Education and Music Culture Committee. His work '浔江渔火' performed at the conference.",
  },
  {
    date: '1956',
    text: "On September 21st, at the 'Peace and Progress' fund sports charity concert, Philippine President Magsaysay once again praised his art of command.",
  },
];

export const year1960s = [
  {
    date: '1960',
    text: "March 15 days of Huang Qing Dynasty poet to compose the word 'Nostalgia', first sung by Yebao Yi. Its music conductor was praised by Philippine President Garcia.",
  },
  {
    date: '1961',
    text: "Formally served as music director and conductor of the Manila Symphony Orchestra. March 21 to 24 days, the Philippines founding father pear brake Centenary in Shen Yong school's two recitals took command.",
  },
  {
    date: '1963',
    text: "On May 29th, he was appointed as an honorary professor by Zhang Qichen, Chairman and Founder of the Board of Directors of the Private Chinese Culture Institute. December 2and 9 days, conduct an orchestra twenty-fifth anniversary concert in Manila by the Philippine President Macapagal praise. The Board of Directors of the Manila Concert Symphony Orchestra presented the 'Outstanding Command and Outstanding Service Award'.",
  },
  {
    date: '1965',
    text: 'February 10, at their command concert by the Philippine President Macapagal praise. February 25 days, any Philippine-Japanese cultural exchange concert conductor, and violinist Oscar Kaya Valley, the Philippines, Taiwan and Azusa Fujita Deng pianist vocalist Shin Yong School Orchestra.',
  },
  {
    date: '1966',
    text: "Awarded by the KIWANIS Association and the International Brotherhood for 'The Contribution to the Philippine Symphony and the Charity Award'. December 21and 22 days, in command of the Philippine Friendship Association to celebrate the concert, Philippine President Ferdinand Marcos to congratulate the success of the concert.",
  },
  {
    date: '1967',
    text: "Join the American Symphony Orchestra as a conductor and serve as a separate member of the alliance. November 18 ~ 20 days, any 'Taiwan Provincial Government Department of Education Symphony Orchestra Concert' guest conductor. He was awarded the 'Haiguang Overseas Chinese Affairs Medal'; he was hired as a philosopher by Zhang Qichen, the president of the Chinese Academy of Sciences.",
  },
  {
    date: '1968',
    text: 'As a representative of the Philippines, he attended the International Music Council meeting in New York and Washington. Until 1974, he served as a representative of the Philippines to attend the UNESCO International Music Education Conference for seven consecutive years.',
  },
  {
    date: '1969',
    text: 'In May, two performances directed by the Manila Symphony Orchestra and Taiwanese vocalist Ms. Sun Shaoru sensationalized the Filipino music scene.',
  },
];

export const year1970s = [
  {
    date: '1970',
    text: 'Participated in the International Music Education Conference in Moscow.',
  },
  {
    date: '1971',
    text: 'On July 22nd, Manila played the 32nd Anniversary Concert of the Symphony Orchestra, and its music command activity was once again praised by Philippine President Marcos. In October, the third time was appointed by the Philippine Council of the United Nations Educational and Educational Organization to attend the 14th International Music Annual Meeting of the International Music Council in Moscow.',
  },
  {
    date: '1972',
    text: "February 25 days, commanding the Manila Symphony Orchestra playing 'Tchaikovsky Night' concert, whose music director activities again won the Philippine President Ferdinand Marcos affirmed.",
  },
  {
    date: '1973',
    text: 'On September 8, the 15th International Music Annual Conference was held in Geneva and Vienna.',
  },
  {
    date: '1974',
    text: 'At the International Music Education Conference in Peth, Australia . December 31, Ms. Ye Baoyi suffering from pancreatic cancer and died in Manila, at the age of 59 years old.',
  },
  {
    date: '1975',
    text: 'December 12 days, any of the Taiwan Provincial Government Department of Education Symphony Orchestra to celebrate the thirtieth anniversary of the group will celebrate music guest conductor.',
  },
  {
    date: '1976',
    text: "8-9 day, as on Taiwan's 'Sixth China Art Song of Night' command, its new orchestral work 'spring back to where', played by the orchestra. November 25to December 2, to participate in the fourth annual meeting of the Asian Composers League, held in Taipei, were called 'Asian composers where to go? He gave a speech and directed the performance of his orchestral work 'The Spirit of the Spirit'.",
  },
  {
    date: '1977',
    text: 'On May 20-22, he was invited to be the conductor of the Taiwan Symphony Orchestra.',
  },
  {
    date: '1978',
    text: "February 16 days, either 'Taipei Century Symphony Orchestra concert of light classical music' musical guest conductor Taiwan sponsored by the Institute.",
  },
];

export const year1980s = [
  {
    date: '1983',
    text: 'In October, he was invited back to Xiamen to participate in the celebration of the 70th anniversary of the establishment of Jimei School by Mr. Chen Jiageng. This is a return to the motherland after nearly 40 years.',
  },
  {
    date: '1986',
    text: 'February 10 days, accompanied by Jimei School Alumni Association honorary chairman Chen Cun, animal husbandry, visited the Jimei. March 10 days, was hired as a visiting professor at Xiamen University art education.',
  },
  {
    date: '1987',
    text: "In April, at the invitation of the Anhui Provincial Department of Culture and Anhui Normal University, an eight- day lecture was held in Wuhu and Hefei . March 5 to 10 days, should the Chinese Musicians Association and Jilin, Jilin Branch Reception Office of Taiwan compatriots invited to give lectures in Changchun City and command. On the 19th, a concert was held at the Harbin Workers Cultural Palace Theater. On June 3, he was invited to participate in the 'National Fujian Music Academy History Seminar' hosted by the Fujian Provincial Institute of Art in Fuzhou. At the meeting, he introduced the history of the 'National Fujian Music Academy'. He was hired by the Fujian Provincial Department of Culture as the music director of the Provincial Song and Dance Theatre and the chief guest conductor of the orchestra. He was invited to be the judge of the 1987 Chinese and Western Cup 'Shanghai International Music Competition' Chinese style piano creation and performance competition. December 1 to 11 days, the Office of Culture and the International Cultural Exchange Center of Fujian Province, was invited by the orchestra Fujian Province Song and Dance Theater, the provincial art school, Fuzhou Song and Dance Troupe coalition symphony orchestra, in Fuzhou, Quanzhou, Zhangzhou, Xiamen Waiting for the city to tour 12 performances.",
  },
  {
    date: '1988',
    text: "January 10 days, was invited to serve Fuzhou, the first 'warm Rongcheng' Hundred Flowers Award literary contest chief adviser Arts Advisory Board, seven thousand, 'Year of the Dragon flowers dance Conference' chorus conductor. In March, he was invited to participate in the 'Music Chinese Style Seminar' jointly organized by the Fujian Provincial Institute of Art and the Music Department of the National Taiwan Normal University. On the 3rd, he was invited to participate in the Nanyin Academic Seminar held by the Quanzhou Nanyin Conference Singing Organizing Committee. On April 8, the Quanzhou Children's Symphony Orchestra was founded. In September, the Fujian Social Music Academy was founded and the first opening ceremony was held. November 30 academic report, the candidates for the honorary professor of Fujian Normal University, and made a speech entitled 'three factors, two roads, a' heart ',' the.",
  },
  {
    date: '1989',
    text: "Jaunary 19 days to submit 'a report on organized jointly by the Fujian Academy of Music's society,' the Fujian provincial government. On August 29th, he participated in the Friendship Exchange Agreement Ceremony of the School of Art Education of Xiamen University and the College of Art of the Philippine Women's University in Manila. November 11 days, 'the National Fujian College of Music Alumni Association' was established in Fuzhou, he served as honorary president.",
  },
];

export const year1990s = [
  {
    date: '1990',
    text: 'In March, former Fujian Provincial Party Secretary Xiang Nan and his wife Wang Zhixin took a group photo with Mr. Tsai Chi-Kun and Mr. Zhao Wei (first from left) in Beijing.',
  },
  {
    date: '1990',
    text: 'December 5 days, to participate in Nanchang, Jiangxi, Fujian Music College Alumni Council representative will for the first time, and was invited to lecture at Jiangxi Normal University.',
  },
  {
    date: '1991',
    text: "On July 30, approved by the Fujian Provincial Education Commission, they agreed to establish the Fuzhou Branch and the Xiamen Branch of the Fujian Social Music College. 10 end of the month to participate in Ka Xila ( Kasilac ) headed by Dr. 'Filipino musician delegations' visit Taiwanese.",
  },
  {
    date: '1992',
    text: "January 19-20, Fujian Academy of Music Society first board of directors was held in Fuzhou, was appointed honorary president of the hospital board of directors, and temporarily served as dean. On October 16th, the famous work of the early years - orchestral music '浔江渔火' was listed as 'the 20th century Chinese music classic' by the Chinese National Culture Promotion Association .",
  },
  {
    date: '1993',
    text: "February 21 days, the executive vice governor of Fujian Province, Chen Mingyi, vice chairman of the International Cultural Exchange Center, executive vice president Huang Ming, vice chairman Zhang Yu Min and other leaders, to talk to the overseas financing and other issues on the establishment of social Fujian Academy of Music. March 25 days, 'Tan Kah Kee International Society,' the first session of the Board in 1993March 25 officially set up in Xiamen, and Chen Ning Yang, Samuel Ting, Chang-Lin Tien, Chern, Zhou Guangzhao, Lu Jiaxi, Liang Piyun, Lim, Zhuang Yanlin, promised equality, Zhang Chukun Well-known Chinese and foreign celebrities were elected as honorary advisors. On July 12th, a representative of the Taiwan Symphony Orchestra led by Chairman Chen Chengxiong came to visit. On July 30th, Fujian Social Music College was laid in the first mountain village of Cangshan District, Fuzhou. The 'Comparative Study of Music', sponsored by the Fujian Academy of Social Music, was the first academic journal of comparative study of music in China. On September 11, in Los Angeles, he hired Tsai Songfang as vice chairman, Chen Guobei and Lei Yongyuan as honorary vice chairman.",
  },
  {
    date: '1994',
    text: "On April 9th, Huang Ming, executive vice chairman of the Fujian Provincial International Cultural and Economic Exchange Center, Vice Chairman Zhang Yimin, Provincial Education Commission, Provincial Government Office and other relevant departments jointly discussed the first enrollment of the Fujian Conservatory of Music. In August, the first private music school in China, Fujian Conservatory of Music, invested more than 10 million yuan in the school building in Shoushan Village, Cangshan District, Fuzhou City, Fujian Province. On September 7th, I participated in the 'Three Board of Directors of the Chen Jiageng International Society' in Hong Kong and reported on the school running of the Fujian Conservatory of Music. November 21, Fujian Academy of Music held its first anniversary ceremony. In the 1994-1995 school year, the college recruited the first batch of freshmen and opened three majors: vocal music, keyboard, orchestra.",
  },
  {
    date: '1995',
    text: "May 5, held the inauguration of the Fujian Academy of Music residential-cum-fill lines to commemorate the founding of the General Assembly. From the end of November to the beginning of December, I made a special trip to Taiwan to participate in the celebration of the 50th anniversary of the 'Taiwan Provincial Symphony Orchestra' . In December, it was awarded the 'Certificate of Excellence' by the American International Distinguished Persons Center and approved by the International Celebrity Dictionary Editorial Board at the US Conference. ",
  },
  {
    date: '1996',
    text: 'On April 27th, the Fujian Conservatory of Music and the Confucius Institute at the Central University of the Philippines held a signing ceremony for the sister college. On September 18th, Fujian Provincial Party Committee Secretary Jia Qinglin and Deputy Secretary He Shaochuan led the relevant departments of the provincial and municipal departments to the Fujian Conservatory of Music to help solve practical problems.',
  },
  {
    date: '1997',
    text: "February 12-18 May, led by the Fujian Academy of Music students and teachers to the West Fujian Changting, Citylink, Shanghang only town, Furuta town and other places to carry out 'to send music to the countryside' sympathy performances. On August 23-27, he led the teachers and students of the Fujian Conservatory of Music to Fuan, Rongrong and Fuding in the old districts of Jidong to carry out the 'Send Music to the Countryside' condolence performance.",
  },
  {
    date: '1998',
    text: "February 12 days, the rate of Fujian College of Music students and teachers to Sanming, Wing, Ninghua, Jianning, Taining and other places, 'Music Tour' tour to show sympathy. November 21, Fujian Academy of Music held the fourth anniversary of establishment of the hospital Anniversary activities, the famous conductor Li Delun hired as a consultant.",
  },
  {
    date: '1999',
    text: "4 months -5 months, the restructuring of approval for the issue of Fujian Academy of Music twice went to Beijing to submit the relevant application report to the Minister of Education Chen Zhili. On May 24th, he was interviewed by Jia Qinglin, member of the Political Bureau of the CPC Central Committee and secretary of the Beijing Municipal Committee. On July 12, President Zhuang Shanyu of Huaqiao University signed an agreement in Quanzhou on the operation of the National Fujian University of Music in the National Overseas Chinese University. November 10 days, hire a visiting scientist Taiwan famous music school Yong Shen as Vice Chairman and the first college professor emeritus. December 8-16, sponsored by the Cultural Department of Fujian Province, Fujian Province Song and Dance Theater Symphony Orchestra's 'Professor Tsai Chi-Kun Ballantine opened a symphonic concert' was held in Quanzhou, Fujian Province, Xiamen, Fuzhou, a complete success, to end their own A commanding career of more than sixty years.",
  },
];

export const year2000s = [
  {
    date: '2000',
    text: 'November 21, held the sixth anniversary of the establishment of the Fujian Academy of Music cum establish Fujian Huaqiao University School of Music festival. Approved by the Ministry of Education and the provincial government, Fujian Conservatory of Music is the only private music college in Fujian Province. It is affiliated to Huaqiao University in a relatively independent form, and has established four departments including vocal music, keyboard, orchestral music and compositional command. Undergraduate.',
  },
  {
    date: '2001',
    text: 'November 21, Fujian Academy of Music held the seventh anniversary celebration of establishment of the hospital, renowned conductor Bian Zushan invited to give lectures.',
  },
  {
    date: '2002',
    text: "December 1 day invited to attend the Taiwan Symphony Orchestra 57th Anniversary of group celebration. On the 27th, the book 'Yi De Shuangxin - Tsai Chi-Kun' was published in Taiwan.",
  },
  {
    date: '2003',
    text: "January 10 days, with Austria Vienna Academy of Music Dean Joseph - signed a friendship and cooperation agreement on behalf of both houses of Schmidt, to promote bilateral exchanges. In April, he was appointed as the deputy director of the Fujian Competition District Organizing Committee by the Organizing Committee of the National Private College Students' Style Competition. In May, the first 'Shi Lang Cup' Chinese Minnan Language Song TV Competition jointly organized by the Chinese Musicians Association and other units was appointed as the chairman of the jury. November 21, held the 'Fujian Academy of Music was founded tenth anniversary of the inauguration of the new homes.' December 20 days, won the third special prize 'China Music Golden Bell Award' issued by the Organizing Committee - 'lifelong Medal of Honor.'",
  },
  {
    date: '2004',
    text: "February 7, was elected by the Central Television and other units jointly organized the 'Fujian FuTsai Cup • touched' 2003 Top Ten characters. March 21 days due to illness died at the residence of the Fujian Academy of Music. March 26 morning, Professor Tsai Chi-Kun memorial service held at the Fujian Academy of Music.",
  },
];

export default {
  early,
  year1940s,
  year1950s,
  year1960s,
  year1970s,
  year1980s,
  year1990s,
  year2000s,
};
