import React from 'react';
import Header from '../Header/header';
import styles from './videos.module.scss';

const Videos = (): JSX.Element => {
  const sourceLink = (title: string, url: string) => {
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {title}
      </a>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <iframe
        title="Tsai Chi-Kun Last Concerts"
          frameBorder="0"
          height="300"
          src="https://v.qq.com/txp/iframe/player.html?vid=a0507wajnvi"
          allowFullScreen={true}
        />
        <caption>
          <p>1999 Documentary on Tsai Chi-Kun's last symphony concert</p>
          <small className={styles.source}>
                Source: {sourceLink('Tencent Video', 'http://v.qq.com/x/page/a0507wajnvi.html')}
          </small>
        </caption>
        <iframe
        title="CCTV4 Tsai Chi-Kun Life Highlights"
          frameBorder="0"
          height="300"
          src="https://haokan.baidu.com/v?vid=4546962365489933789&pd=pcshare"
          allowFullScreen={true}
        />
        <caption>
          <p>CCTV4 Highlights of Tsai Chi-Kun's life</p>
          <small className={styles.source}>
                Source: {sourceLink('Haokan Video', 'https://haokan.baidu.com/v?pd=wisenatural&vid=4546962365489933789')}
          </small>
        </caption>
        <iframe
          title="The song for the first Taiwan Provincial Games created by Tsai Chi-Kun"
          frameBorder="0"
          height="300"
          src="https://player.bilibili.com/player.html?aid=673194085&bvid=BV1wU4y1L7AY&cid=340035846&page=1"
          allowFullScreen={true}
        />
        <caption>
          <p>The song for the first Taiwan Provincial Games created by Tsai Chi-Kun</p>
          <small className={styles.source}>
                Source: {sourceLink('Bilibili Video', 'https://www.bilibili.com/video/av673194085')}
          </small>
        </caption>
      </div>
    </>
  );
};

export default Videos;
