import React from 'react';
import { Accordion } from 'react-bootstrap';
import years from '../../data/chronology';
import Header from '../Header/header';
import styles from './chronology.module.scss';

interface IChronologyItem {
  date: string;
  text: string;
}

const Chronology = (): JSX.Element => {
  const accordionBody = (
    label: string,
    events: IChronologyItem[]
  ): JSX.Element => {
    return (
      <>
        <Accordion.Header>
          <span className={styles.date}>{label}</span>
        </Accordion.Header>
        <Accordion.Body>
          <ul>
            {events.map((item, index) => {
              return (
                <li key={index}>
                  <span className={styles.date}>{item.date}</span>
                  <span className={styles.text}>{item.text}</span>
                </li>
              );
            })}
          </ul>
        </Accordion.Body>
      </>
    );
  };

  return (
    <>
      <h1>Chronology</h1>
      <div className={styles.chronologyContainer}>
        <Accordion>
          <Accordion.Item eventKey="0">
            {accordionBody('1912 - 1939', years.early)}
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            {accordionBody('1940s', years.year1940s)}
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            {accordionBody('1950s', years.year1950s)}
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            {accordionBody('1960s', years.year1960s)}
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            {accordionBody('1970s', years.year1970s)}
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            {accordionBody('1980s', years.year1980s)}
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            {accordionBody('1990s', years.year1990s)}
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            {accordionBody('2000s', years.year2000s)}
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default Chronology;
